/**
 *  Product Card
 *  
 *  Column aligned card taking up half of screen width.
 *  Containing product title, text and image.  
 *
 */ 

.product-card__column-box {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    box-sizing: border-box;
}

.product-card__text {
    max-width: 80%;
    text-align: center;
    font-size: 1.5rem;
    font-family: TisaSansPro;
}

.product-card__title {
    font-size: 2.3rem;
    font-family: TisaPro-Medium;
    padding: 2rem 0 1rem 0;
}

.product-card__button {
    padding: 2rem 0 1rem 0;
}

.product-card__image-box{
    position: relative;
    overflow: hidden;
    z-index: 0;
}

.product-card__image-main {
    z-index: -1;
}
.product-card__image-detail {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 100%;
    width: 100%;
    height: auto;
    transition: all 1s ease 0s;
    z-index: 0;
}

.product-card__image-box:hover .product-card__image-detail {
    left: 0%;
    width: 100%;
    height: auto;
}

@media (max-width: 800px){
    .product-card__column-box {
        width: 100%;
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: center;
    }
}