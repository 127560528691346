.person-card__box {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 1.5rem;
    padding : 1rem;
}

.person-card__image-box {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 50px;
    max-width: 90px;
    flex-basis: 20%;
    border: 1px slid seagreen;
}

.person-card__details-box {
    margin-left: 2rem;
    display: flex;
    flex-direction: column;
    font-family: TisaSansPro;
    flex-basis: 65%;
}

.person-card__person-title {
    margin-bottom: 1rem;
    display: flex;
    flex-wrap: wrap;
    font-size: 1.7rem;
}

.person-card__person-name {
    font-family: TisaPro-Medium;
}

.person-card__person-job {
    font-family: TisaSansPro;
}

@media (max-width: 800px){
    .person-card__details-box {
        flex-basis: 80%;
    }
    .person-card__box {
        flex-direction: column;
        align-items: stretch;
    }

    .person-card__image-box {
        min-width: initial;
        max-width: initial;
        margin-bottom: 2rem;
    }

    .person-card__image-main {
        width: 70px;

    }

    .person-card__details-box {
        margin: 0 1rem;
        text-align: center;
        align-items: center;
    }

    .person-card__person-title {
        flex-direction: column;
        text-align: center;
        align-items: center;
        justify-content: center;
    }
}