.wp-access {
    font-size: 2rem;
    margin: 4rem;
}

.license-section {
    margin-top: 2rem;
    font-size: 16px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.license-section > h4 {
    font-size: 20px;
    font-family: TisaSansPro-Medium;
}

.license-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.license-list > li {
    margin: 0;
    padding: 0;
    font-size: 16px;
}

.license-label {
    font-family: TisaSansPro-Bold;
}