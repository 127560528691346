/*
 * Standrad elements in content box: title, text
 */ 
.section__title {
    margin-top: 5rem;
    font-size: 2.8rem;
}

.section__text {
    padding: 0 1rem;
    font-family: TisaSansPro;
    font-size: 1.7rem;
    text-align: center;
    max-width: 600px;
}