/**
 *  index.css
 *
 *  Contains page wide settings such as fonts (including font imports).
 *  CSS resets and default styling of tags is added here when required.
 */ 


@font-face {
  font-family: TisaPro;
  src: url("./fonts/TisaPro/TisaPro.woff") format("woff");
}

@font-face {
  font-family: TisaPro-Bold;
  src: url("./fonts/TisaPro/TisaPro-Bold.woff") format("woff");
}

@font-face {
  font-family: TisaPro-Light;
  src: url("./fonts/TisaPro/TisaPro-Light.woff") format("woff");
}

@font-face {
  font-family: TisaPro-Italic;
  src: url("./fonts/TisaPro/TisaPro-Italic.woff") format("woff");
}

@font-face {
  font-family: TisaPro-Medium;
  src: url("./fonts/TisaPro/TisaPro-Medium.woff") format("woff");
}

@font-face {
  font-family: TisaSansPro;
  src: url("./fonts/TisaSansPro/TisaSansPro.woff") format("woff");
}

@font-face {
  font-family: TisaSansPro-Light;
  src: url("./fonts/TisaSansPro/TisaSansPro-Light.woff") format("woff");
}

@font-face {
  font-family: TisaSansPro-Italic;
  src: url("./fonts/TisaSansPro/TisaSansPro-Italic.woff") format("woff");
}

@font-face {
  font-family: TisaSansPro-Bold;
  src: url("./fonts/TisaSansPro/TisaSansPro-Bold.woff") format("woff");
}

@font-face {
  font-family: TisaSansPro-Medium;
  src: url("./fonts/TisaSansPro/TisaSansPro-Medium.woff") format("woff");
}

.grecaptcha-badge { 
    visibility: hidden;
}

.emphasized {
    font-family: TisaSansPro-Medium, Helvetica, Arial, sans-serif;
}

* {
    box-sizing: border-box;
}
/**
 *  HTML tag: page wide defaults
 *  - height:set to full-height (100vh) as this is a prerequisite for parallay effect
 *  - font-size: define 1rem to 10px on large screens, less than 10px on screens
 *    smaller than 500px
 *  - font-family: standard font as 
 */ 
html {
  height: 100%;
  font-size: 10px;
  font-family: TisaPro, Arial, Helvetica, sans-serif;
}

/**
 *  BODY tag: page wide defaults
 *  -  height:set to full-height (100vh) as this is a prerequisite for parallay effect
 */
body {
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
  margin: 0;
  padding: 0;
}

h1 {
  margin: 0;
  font-size: 3rem;
  font-weight: normal;
  font-family: TisaPro-Light, Georgia, 'Times New Roman', Times, serif;
}

h2 {
  font-size: 2rem;
  font-family: TisaSansPro-Bold, Helvetica, Arial, sans-serif;
}

p {
  font-family: TisaSansPro, Helvetica, Arial, sans-serif;
  font-weight: 1rem;
}

img {
  max-width:100%;
}

a:link {
  text-decoration: none;
  color: black;
}

a:visited {
  text-decoration: none;
  color: black;
}

a:hover {
  text-decoration: none;
  color: #B14E79;

}

a:active {
  text-decoration: none;
  color: #B14E79
}

button {
  margin: 0;
  border: 0;
  outline: none;
  box-sizing: content-box;
}

ul {
  margin: 0;
  list-style-type: none;
  padding: 0.5rem 2.5rem;
}

input, textarea {
  border-radius: 2px;
  font-family: TisaPro;
  font-size: 1rem;
  text-indent: 0.5rem;
  color: #1a2c46;
  border: 1px solid #5B7398;
  border-width: thin;
  outline: none;
  margin: 1rem 0;
  line-height: 2;
}

textarea {
  resize: none;
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color:#5B7398;
}
::-moz-placeholder { /* Firefox 19+ */
  color: #5B7398;;
}
:-ms-input-placeholder { /* IE 10+ */
  color: #5B7398;;
}
:-moz-placeholder { /* Firefox 18- */
  color: #5B7398;
}

#pagination-menu li {
  font-size: 14px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace
}